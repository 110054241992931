import api from '@apiInstance';

export default {
  add(body) {
    return api.post('ticket_monitorings', body);
  },
  get(id) {
    return api.fetch('ticket_monitorings/' + id);
  },
  list(params) {
    return api.fetch('/ticket_monitorings', { params });
  },
  update(body) {
    return api.patch('ticket_monitorings', body);
  },
  delete(id) {
    return api.remove('ticket_monitorings', id);
  },
};
