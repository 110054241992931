import api from '@apiInstance';

export default {
  add(body) {
    return api.post('ticket_monitoring_remarks', body);
  },
  get(id) {
    return api.fetch('ticket_monitoring_remarks/' + id);
  },
  list(params) {
    return api.fetch('/ticket_monitoring_remarks', { params });
  },
  update(body) {
    return api.patch('ticket_monitoring_remarks', body);
  },
  delete(id) {
    return api.remove('ticket_monitoring_remarks', id);
  },
};
